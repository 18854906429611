<template>
    <div id="box">
        <Header :headerIndex="4" :nextLevel="4"></Header>
        <div class="container">
            <div class="flex-box flex-col-start ptb30 m1340 flex-col-stretch">
                <LeftNav :leftIndex="4"></LeftNav>
                <div class="flex-grow-1 ml20 pt30 plr30 personal coins_account">
                    <div class="flex-box pb20">
                        <div class="fs20 fwb pr20">{{$t('ap')}}</div>
                        <a href="javascript:;" class="tips"></a>
                    </div>
                    <div class="coin_box mb40">
                        <div class="fs14 col5 pb5">AP</div>
                        <div class="fs24 colb fwb">{{ user.ap | numberToCurrency }}</div>
                    </div>
                    <div class="fs20 fwb pb20">{{$t('records')}}</div>
                    <div class="title_box flex-box fs16 col3">
                        <div class="title_item">{{$t('date')}}</div>
                        <div class="title_item ">{{$t('from')}}</div>
                        <div class="title_item">{{$t('number')}}</div>
                    </div>
                    <div class="list_box flex-box fs16 col3" v-for="item in list">
                        <div class="list_item">{{ item.createtime_text }} </div>
                        <div class="list_item ">{{item.desc}}</div>
                        <div class="list_item">{{ item.num }}</div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import cookie from "vue-cookies";

import { numberToCurrency } from "../assets/filters";
export default {
    filters: {
        numberToCurrency
    },
    data() {
        return {
            user:'',
            list:'',

        };
    },
    created(){
        this.initdata()
    },
    methods: {
        initdata(){
            this.getuser()
            this.loglist()
        },
        getuser(){
            this.$api.apiUserInfo({}).then(ret=>{
				if(ret.code==1){
					this.user = ret.data.userinfo;
				}
			})
        },
        loglist(){
            this.$api.apiAploglist({}).then(ret=>{
                if(ret.code==1){
                    this.list=ret.data
                }
            })
        },
    }
};
</script>
